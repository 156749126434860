'use client';

import classNames from 'classnames';
import { useEffect } from 'react';
import styles from './index.module.scss';

export function MegaNavClient() {
  useEffect(() => {
    const serviceMenuToggle = document.getElementById('serviceMenuToggle') as HTMLInputElement;
    const motToggle = document.getElementById('motToggle') as HTMLInputElement;
    const menuToggle = document.getElementById('menuToggle') as HTMLInputElement;
    const resetToggle = document.getElementById('resetToggle') as HTMLInputElement;
    const garageToggle = document.getElementById('garageToggle') as HTMLInputElement;
    const blogToggle = document.getElementById('blogToggle') as HTMLInputElement;

    const handleServiceMenuToggleChange = () => {
      if (serviceMenuToggle.checked) {
        motToggle.checked = true;
        garageToggle.checked = false;
        blogToggle.checked = false;
      } else {
        resetToggle.checked = true;
      }
    };

    const handleMenuToggleChange = () => {
      if (!menuToggle.checked) {
        resetToggle.checked = true;
        garageToggle.checked = false;
        blogToggle.checked = false;
      }
    };

    const handleGarageToggleChange = () => {
      if (garageToggle.checked) {
        serviceMenuToggle.checked = false;
        resetToggle.checked = true;
        blogToggle.checked = false;
      }
    };

    const handleBlogToggleChange = () => {
      if (blogToggle.checked) {
        serviceMenuToggle.checked = false;
        resetToggle.checked = true;
        garageToggle.checked = false;
      }
    };

    serviceMenuToggle.addEventListener('change', handleServiceMenuToggleChange);
    menuToggle.addEventListener('change', handleMenuToggleChange);
    garageToggle.addEventListener('change', handleGarageToggleChange);
    blogToggle.addEventListener('change', handleBlogToggleChange);

    return () => {
      serviceMenuToggle.removeEventListener('change', handleServiceMenuToggleChange);
      menuToggle.removeEventListener('change', handleMenuToggleChange);
      garageToggle.removeEventListener('change', handleGarageToggleChange);
      blogToggle.removeEventListener('change', handleBlogToggleChange);
    };
  }, []);

  return (
    <>
      <input
        type="checkbox"
        id="menuToggle"
        className={classNames(styles.hiddenCheckbox, styles.menuToggle)}
      />
      <input
        type="checkbox"
        id="serviceMenuToggle"
        className={classNames(styles.hiddenCheckbox, styles.serviceMenuToggle)}
      />
      <input
        type="radio"
        name="toggleGroup"
        id="motToggle"
        className={classNames(styles.hiddenCheckbox, styles.motToggle)}
      />
      <input
        type="radio"
        name="toggleGroup"
        id="servicingToggle"
        className={classNames(styles.hiddenCheckbox, styles.servicingToggle)}
      />
      <input
        type="radio"
        name="toggleGroup"
        id="repairsToggle"
        className={classNames(styles.hiddenCheckbox, styles.repairsToggle)}
      />
      <input
        type="radio"
        name="toggleGroup"
        id="diagnosticsToggle"
        className={classNames(styles.hiddenCheckbox, styles.diagnosticsToggle)}
      />
      <input
        type="radio"
        name="toggleGroup"
        id="resetToggle"
        className={classNames(styles.hiddenCheckbox, styles.resetToggle)}
      />
      <input
        type="checkbox"
        id="garageToggle"
        className={classNames(styles.hiddenCheckbox, styles.garageToggle)}
      />
      <input
        type="checkbox"
        id="blogToggle"
        className={classNames(styles.hiddenCheckbox, styles.blogToggle)}
      />
    </>
  );
}
